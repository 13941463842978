import React, { Component } from "react";
import FontAwesome from "react-fontawesome";
import { Link, withRouter } from "react-router-dom";
import { stillUrls } from "../../redux/settings";
import { Transition } from "react-transition-group";
import { connect } from "react-redux";
import _ from "lodash";

const homeLinks = (feature, user) =>
  ["user", "admin"].includes(user.role)
    ? [
        { path: "/", label: "Home", icon: "home" },
        {
          path: "/assessments/new",
          label: "New Assessment",
          icon: "tachometer",
        },
        { path: "/assessments/home", label: "Assessments", icon: "clock-o" },
        {
          path: "/wiki/country_pages",
          label: "Immigration by Country",
          icon: "globe",
        },
        { path: "/calendar", label: "Calendar", icon: "calendar" },
        {
          path: "/permiso-reports",
          label: "Reports",
          icon: "list-ul",
          menu: [
            {
              path: "/reports/destinations",
              label: "Summary of Assessments and Outcomes",
            },
            {
              path: "/reports/location",
              label: "Current Travelers by Country",
            },
            {
              label: "Tax Report",
              path: "/reports/tax",
            },
            {
              label: "Custom Report",
              path: "/reports/custom",
            },
          ],
        },
        { path: "/profile", label: "Profile", icon: "user-circle-o" },
        { path: "/support", label: "Support", icon: "question" },
      ]
    : [];

const getNavLinks = (feature, user) =>
  [
    ...homeLinks(feature, user),
    ["admin", "dev"].includes(user.role) && {
      path: "/domain_admin",
      label: "Domain Admin",
      menu: [
        {
          path: "/domain_admin/become_user",
          label: "Become User",
        },
        feature.travel_assessments && {
          path: "/domain_admin/customizations",
          label: "Activity Rules",
        },
        feature.travel_assessments && {
          path: "/domain_admin/outcome_rules",
          label: "Outcome Rules",
        },
        { path: "/domain_admin/settings", label: "Settings" },
        {
          path: "/domain_admin/country_tool",
          label: "Country Tool",
        },
        feature.wiki && {
          path: "/domain_admin/substitutions",
          label: "Substitutions",
        },
        feature.wiki && {
          path: "/domain_admin/pages",
          label: "Pages",
        },
        feature.travel_assessments && {
          path: "/domain_admin/screening_questions",
          label: "Screening Questions",
        },
        ["dev"].includes(user.role) && {
          path: "/domain_admin/user_roles",
          label: "User Roles",
        },
        { path: "/domain_admin/announcements", label: "Announcements" },
        feature.travel_assessments && {
          path: "/domain_admin/unavailable_country_messages",
          label: "Unavailable Messages",
        },
      ],
    },
    ["dev"].includes(user.role) && {
      path: "/permiso_admin",
      label: "Permiso Admin",
      menu: [
        { path: "/permiso_admin/outcome_rules", label: "Outcome Rules" },
        { path: "/permiso_admin/countries", label: "Countries" },
        { path: "/permiso_admin/special_groups", label: "Special Groups" },
        { path: "/permiso_admin/pages", label: "Pages" },
        { path: "/permiso_admin/news", label: "News" },
        { path: "/permiso_admin/announcements", label: "Announcements" },
      ],
    },
  ].filter((l) => l && l.path);

class OpenMenu extends Component {
  state = {
    viewTab: null,
  };

  setViewTab(viewTab) {
    this.setState({ viewTab });
  }

  renderLink({ back, icon, image, label, menu, parentPath, path }) {
    if (
      !path ||
      (this.state.viewTab && ![parentPath, path].includes(this.state.viewTab))
    ) {
      const hasSubMenu = menu && menu.find((l) => l && l.menu);
      if (hasSubMenu) {
        return menu
          .filter((l) => l && l.menu)
          .map((l) => this.renderLink({ ...l, parentPath: path }));
      }

      return null;
    }

    if (menu && this.state.viewTab === path) {
      return [
        {
          back: parentPath || true,
          icon: "angle-double-left",
          label,
          path,
        },
        ...menu,
      ]
        .filter((l) => l)
        .map((l) =>
          this.renderLink({
            ...l,
            icon: l.back ? l.icon : null,
            parentPath: path,
          })
        );
    }

    const inner = (
      <div
        id={path}
        onClick={() => {
          if (back) {
            this.setViewTab(back === true ? null : back);
          } else if (menu) {
            this.setViewTab(path);
          }
        }}
      >
        <p className="nav-text">
          {icon && <FontAwesome name={icon} />}
          {image && (
            <img
              src={`https://s3-us-west-1.amazonaws.com/permiso-assets/images/icons/${image}`}
              alt=""
            />
          )}
          {label}
        </p>
      </div>
    );

    const key = `${back ? parentPath : ""}-${path}`;

    if (back || menu) {
      return (
        <div className="nav-box" key={key} style={{ cursor: "pointer" }}>
          {inner}
        </div>
      );
    }

    if (path.startsWith("http")) {
      return (
        <a
          className="nav-box"
          key={key}
          onClick={() =>
            window.confirm(
              "You are about to be redirected outside of Pearl Hub"
            ) && window.open(path, "_blank")
          }
        >
          {inner}
        </a>
      );
    }

    return (
      <Link className="nav-box" key={key} to={path}>
        {inner}
      </Link>
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.viewTab && this.props.viewTab !== prevProps.viewTab) {
      this.setViewTab(this.props.viewTab);
    }
  }

  render() {
    const {
      domain: {
        permiso_admin_setting: { feature },
      },
      user,
    } = this.props;

    return (
      <div
        className={`${this.state.viewTab ? "is-viewing-tab" : ""} side-nav`}
        style={{ position: "relative" }}
      >
        <div
          className="nav-box"
          id="close-menu"
          onClick={this.props.toggleMenu}
          style={{
            cursor: "pointer",
            color: "white",
          }}
        >
          <FontAwesome
            className="nav-icon __rsnav___item"
            style={{
              margin: "12px 0 12px 12px",
              borderBottom: "0",
            }}
            name="angle-double-left"
          />
          <span
            className="nav-text"
            style={{ paddingLeft: 12, verticalAlign: -2, color: "white" }}
          >
            Close Menu
          </span>
        </div>

        {getNavLinks(feature, user).map(this.renderLink.bind(this))}
      </div>
    );
  }
}

class ClosedMenu extends Component {
  renderLink({ icon, image, label, menu, path }) {
    if ((!icon && !image) || !path) {
      return null;
    }

    const inner = (
      <div id={path}>
        <div className="nav-icon">
          {icon && <FontAwesome name={icon} />}
          {image && (
            <img
              src={`https://s3-us-west-1.amazonaws.com/permiso-assets/images/icons/${image}`}
              alt=""
            />
          )}
        </div>
        <p className="nav-text">{label}</p>
      </div>
    );

    if (path.startsWith("http")) {
      return (
        <a
          className="nav-box"
          key={path}
          onClick={() =>
            window.confirm(
              "You are about to be redirected outside of Pearl Hub"
            ) && window.open(path, "_blank")
          }
        >
          {inner}
        </a>
      );
    }

    return (
      <Link
        className="nav-box"
        key={path}
        to={path}
        onClick={() => {
          if (menu) {
            this.props.setRootViewTab(path);
            this.props.toggleMenu();
          }
        }}
      >
        {inner}
      </Link>
    );
  }

  render() {
    const {
      domain: {
        permiso_admin_setting: { feature },
      },
      user,
    } = this.props;

    return (
      <div
        className="side-nav side-nav-closed"
        style={{ position: "relative" }}
      >
        <div
          className="nav-box"
          onClick={this.props.toggleMenu}
          id="close-menu"
          style={{
            color: "white",
            cursor: "pointer",
          }}
        >
          <FontAwesome
            className="nav-icon __rsnav___item"
            style={{
              margin: "0 0 12px 3px",
              fontSize: "1.25em",
              borderBottom: "0",
            }}
            name="angle-double-right"
          />
        </div>

        {getNavLinks(feature, user).map(this.renderLink.bind(this))}
      </div>
    );
  }
}

const duration = 400;
const defaultStyle = {
  transition: `transform ${duration}ms ease`,
};

const SlideTransition = ({
  children,
  in: inProp,
  out,
  onExiting,
  onExited,
}) => {
  const transitionStyles = {
    entering: {
      transform: `translateX(${out}px)`,
    },
    entered: {
      transform: `translateX(${out}px)`,
    },
    exiting: {
      transform: "translateX(0)",
    },
    exited: {
      transform: "translateX(0)",
    },
  };
  return (
    <Transition
      in={inProp}
      timeout={duration}
      onExiting={onExiting}
      onExited={onExited}
    >
      {(status) => {
        const currentStyles = transitionStyles[status];
        return React.cloneElement(children, {
          style: Object.assign({}, defaultStyle, currentStyles),
        });
      }}
    </Transition>
  );
};

class PermisoSideNav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menuClosed: localStorage.getItem("menuClosed") !== "false",
      viewTab: null,
    };
  }

  componentDidMount() {
    this.props.history.listen((location) => {
      if (this.state.menuClosed) {
        if (!_.includes(stillUrls, location.pathname)) {
          //this.props.toggleMenu()
        }
      }
    });
    if (this.state.menuClosed) {
      document.addEventListener("keydown", this.escFunction, false);
    }
  }

  escFunction = (event) => {
    if (event.keyCode === 27 && this.state.menuClosed) {
      this.toggleMenu();
    }
  };

  toggleMenu = () => {
    localStorage.setItem("menuClosed", `${!this.state.menuClosed}`);
    this.setState((oldState) => ({ menuClosed: !oldState.menuClosed }));
  };

  componentWillUnmount() {
    this.props.history.listen((location, action) => {});
    document.removeEventListener("keydown", this.escFunction, false);
  }

  render() {
    //if (!this.props.user || !["admin", "dev"].includes(this.props.user.role)) {
    //  return null;
    //}

    if (this.props.domain) {
      return (
        <div
          style={{
            position: "relative",
            width: this.state.menuClosed ? "25px" : "245px",
            zIndex: 9,
          }}
          ref={(thing) => {
            this.spaceHolder = thing;
          }}
        >
          <div
            style={{
              position: "absolute",
              width: 0,
            }}
          >
            <SlideTransition
              out={-260}
              in={this.state.menuClosed}
              onExiting={() => {
                this.spaceHolder.style.width = "245px";
              }}
            >
              <div>
                <OpenMenu
                  viewTab={this.state.viewTab}
                  user={this.props.user}
                  domain={this.props.domain}
                  toggleMenu={this.toggleMenu}
                  history={this.props.history}
                />
              </div>
            </SlideTransition>
          </div>
          <div
            style={{
              position: "absolute",
              zIndex: this.state.menuClosed ? 1000 : -1,
            }}
          >
            <SlideTransition
              out={-50}
              in={!this.state.menuClosed}
              onExited={() => {
                this.spaceHolder.style.width = "25px";
              }}
            >
              <div>
                <ClosedMenu
                  setRootViewTab={(viewTab) => this.setState({ viewTab })}
                  toggleMenu={this.toggleMenu}
                  domain={this.props.domain}
                  user={this.props.user}
                />
              </div>
            </SlideTransition>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    domain: state.domain,
  };
};
export default withRouter(connect(mapStateToProps)(PermisoSideNav));
